export const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: true,
};

export const getComputedOffset = (node, setState) => {
  const styles = window.getComputedStyle(node);
  const computedOffset = `${(
    Number(styles.marginLeft.split('px')[0]) + 23.5
  ).toString()}px`;
  setState(computedOffset);
};
